.earnpoint-page {
	.h1message {
		text-align: center;
		height: 300px;
	}
	.uppercase {
		text-transform: uppercase;
	}
	.alignCenter {
		text-align: center;
	}
	.thankuh2 {
		margin-top: 150px;
	}
	.survey-message-div {
		margin: 5%;
	}
	.response-message-div {
		margin-bottom: 5px;
		border-radius: 10px;
		padding: 10px;
	}
}
